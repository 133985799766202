// PAGEWRAP
////////////////////////////

#pageWrap {
	@include breakpoint(large, max) {
		margin-top: rem(70px);
	 }
	
}

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	height: 18rem;

	@include breakpoint(medium) {
		height: 21rem;
	 }

	@include breakpoint(large) {
		height: 28rem;
	 }

	@include breakpoint(giant) {
		height: 35rem;
	 }	

	@include breakpoint(huge) {
		height: 40rem;
	 }

	@include breakpoint(full) {
		height: 50rem;
	 }
	

	.branding {
		display: block;
		max-width: 200px;
		width: 100%;
		height: auto;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}

	.headerslider {
		width: 100%;
		height: 100%;
		.swiper-wrapper {
			.swiper-slide {
				&.slider01 {
					@include responsiveImage('layout/slider/sliderBg01.jpg', (tiny, medium, large), false);
					background-size: cover;
				}

				&.slider02 {
					@include responsiveImage('layout/slider/sliderBg02.jpg', (tiny, medium, large), false);
					background-size: cover;
				}

				&.slider03 {
					@include responsiveImage('layout/slider/sliderBg03.jpg', (tiny, medium, large), false);
					background-size: cover;
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	.btn {
		width: auto;
		display: inline-flex;
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.infobox {
		background-color: $medium;
		padding: 1.5rem 1rem;
		margin-bottom: 1.5rem;
		text-align: center;

		@include breakpoint(tiny) {
			padding: 2.5rem 2rem;
		 }
		
		@include breakpoint(large) {
			padding: 3.5rem 3.5rem 3.5rem 4.5rem;
			text-align: left;
			margin-bottom: 0;
		 }
		

		&:before {
			@extend .icon;
			@extend .icon-info;
			color: $secondary;
			font-size: rem(22px);
			margin-bottom: 1rem;

			// @include breakpoint(large) {
			// 	margin-bottom: 0;
			// 	position: absolute;
			// 	left: 2.75rem;
			// 	font-size: rem(25px);
			// 	margin-top: 0.25rem;
			//  }
			
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
			span {
				display: block;
				font-weight: 700;
			}
			strong {
				
			}
		}
	}

	.contentBg01 {
		@include responsiveImage('index/contentBg01.jpg', (tiny, medium, large), true);
		background-size: cover;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.contentBg02 {
		@include responsiveImage('index/contentBg02.jpg', (tiny, medium, large), true);
		background-size: cover;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;

		.flexHeadline {
			@include breakpoint(medium) {
				display: flex;
				align-items: center;
				justify-content: center;
			 }
		}

		h3.news {
			color: $light;
			font-size: 200%;
			font-weight: 600;

			@include breakpoint(small) {
				font-size: 250%;
			 }

			@include breakpoint(medium) {
				font-size: 300%;
				margin-bottom: 0 !important;
				padding-bottom: 0 !important;
			 }
			
			@include breakpoint(large) {
				font-size: 350%;
			 }
			
			@include breakpoint(giant) {
				font-size: 410%;
			 }

			@include breakpoint(huge) {
				font-size: 430%;
			 }
			

			&:after, &:before {
				display: none;
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

.claimBox {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: $medium;
	padding-bottom: 2rem;

	@include breakpoint(medium) {
		flex-direction: row;
		position: relative;
		background-color: transparent;
		padding-bottom: 0;
		justify-content: space-between;
		max-width: 43rem;
		margin: 0 auto;
		padding-left: 0;

		&:after {
			content: "";
			background-color: $medium;
			width: 90%;
			left: 10%;
			position: absolute;
			height: 100%;
			z-index: -1;
		}
	 }

	@include breakpoint(large) {
		max-width: 50rem;
		margin-top: -5.5rem !important;
		z-index: 1;
		padding-right: 2.5rem;

		&:after {
			height: 84%;
			top: 8%;
		}
	 }

	@include breakpoint(giant) {
		max-width: 60rem;
		padding-right: 3.5rem;
		margin-top: -6.5rem !important;

		&:after {
			height: 86%;
			top: 7%;
		}
	 }
	
	
	

	.siegel {
		max-width: rem(140px);
		z-index: 2;
		height: auto;
		margin-top: -4.5rem;

		@include breakpoint(medium) {
			max-width: rem(155px);
			margin-right: 1.5rem;
			margin-top: -0.25rem;
		 }
		
		@include breakpoint(large) {
			max-width: rem(210px);
			margin-right: 1.25rem;
		 }

		@include breakpoint(giant) {
			max-width: rem(260px)
		 }
						
	}

	blockquote {
		font-weight: 600;
		text-align: center;
		font-size: rem(25px);
		line-height: 125%;
		padding: 1.5rem 0;


		@include breakpoint(small) {
			font-size: rem(32px);
		 }

		@include breakpoint(medium) {
			font-size: rem(27px);
			text-align: left;
			max-width: 16.5rem;
		 }
		
		@include breakpoint(large) {
			font-size: rem(32px);
			max-width: 22rem;
		 }
		
		@include breakpoint(giant) {
			font-size: rem(42px);
			font-weight: 700;
			margin-right: 0.75rem;
		 }
		
		

		span {
			margin-top: 0.5rem;
			line-height: 135%;
			display: block;
			font-weight: 300;
			font-size: rem(20px);


			@include breakpoint(small) {
				font-size: rem(21px);
			 }
			
			@include breakpoint(medium) {
				font-size: rem(17px);
			 }
			
			@include breakpoint(large) {
				font-size: rem(23px);
			 }
			
		}
	}

	.btn {
		@include breakpoint(medium) {
			font-size: rem(16px);
			padding: 0.6rem 4rem 0.6rem 1rem;
			min-width: 11.25rem;
		 }
		
		@include breakpoint(giant) {
			padding: 0.65rem 5rem 0.7rem 2rem;
			font-size: rem(20px);
		 }
		
	}
}

.partner-logos {
	padding: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}



// FOOTER
//////////////////////////////

#footer {
	background: $light;
	

	.row {
		.col {

			.adressBar {
				display: flex;
				flex-direction: column;
				padding: 3rem 0;

				@include breakpoint(large) {
					padding: 4rem 0 2rem;
				 }
				

				@include breakpoint(giant) {
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					padding: 4rem 0;
				 }
				

				strong {
					font-weight: 600;
				}

				.first {
					display: flex;
					flex-direction: column;
					text-align: center;

					@include breakpoint(large) {
						flex-direction: row;
						justify-content: space-between;
						text-align: left;
					 }

					@include breakpoint(giant) {
						width: 60%;
					 }	

					@include breakpoint(huge) {
						width: 56%;
					 }				
					

					address {
						margin-bottom: 1.5rem;

						@include breakpoint(large) {
							margin-bottom: 0;
						 }						
						
						strong, span {
							display: block;
						}
					}

					.contactDetails {
						span {
							display: block;
							span {
								display: inline-block;
							}
						}
					}
				}

				.trenner {
					width: 100%;
					height: 1px;
					max-width: 15rem;
					background-color: darken($medium, 5%);
					margin: 1.5rem auto 0;

					@include breakpoint(large) {
						max-width: none;
						margin: 4rem auto 2rem;
					 }

					@include breakpoint(giant) {
						width: 1px;
						margin: 0;
						height: 3.5rem;
					 }
															
				}

				.second {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					@include breakpoint(large) {
						flex-direction: row;
						justify-content: center;
					 }
					
					

					a{
						max-width: 8rem;
						margin-top: 1.5rem;

						@include breakpoint(large) {
							margin: 0 1rem;
						 }
						
						@include breakpoint(giant) {
							max-width: 5rem;
							margin: 0 0.5rem;
						 }
						
						@include breakpoint(huge) {
							max-width: 8rem;
							margin: 0 1rem;
						 }
						
					}
				}
			}
		}
	}
}

hr.hide {
	@include breakpoint(large,max) {
		display: none;
	 }
	
}

.teamContainer {
	background-color: $medium;
	padding: 2.5rem 1.5rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	height: 100%;

	p {
		margin-bottom: 1rem;

		span, strong {
			display: inline-block;
		}
	}

	span {
		display: block;

		&.name {
			font-weight: 600;
			font-size: 110%;
		}

		&.stelle {
			font-style: italic;
			color: lighten($dark, 10%);
			font-weight: 500;
			margin-bottom: 1rem;
		}
	}
}

.navCol {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 8rem;
	background-color: $medium;

	@include breakpoint(large) {
		justify-content: space-between;
		padding-bottom: 4rem;
		background-color: transparent;
	 }

	@include breakpoint(huge) {
		flex-direction: row;
		align-items: flex-start;
		padding-top: 4rem;
	 }
	

	 .navs {
	 	ul {
	 		&.navFour {
	 			margin-bottom: 0;
	 		}
	 	}
	 	@include breakpoint(large) {
	 		width: 100%;
	 		display: flex;
	 		flex-direction: row;
	 		justify-content: space-between;

	 		ul {
	 			&.navOne {
	 				flex-basis: 18%;
	 			}

	 			&.navTwo {
	 				flex-basis: 27%;
	 			}

	 			&.navThree {
	 				flex-basis: 31%;
	 			}

	 			&.navFour {
	 				flex-basis: 14%;
	 				margin-bottom: 0;
	 			}
	 		}
	 	 }
	 	
	 }
	

	a.branding {
		display: block;
		margin: 3rem auto 1.5rem;
		max-width: 10rem;

		@include breakpoint(large) {
			flex-basis: 100%;
			margin-top: 4rem;
			margin-bottom: 2.5rem;
		 }

		@include breakpoint(giant) {
			max-width: 13rem;
		 }

		@include breakpoint(huge) {
			margin-right: 7rem;
			margin-top: 0;
		 }
		
		
	}
	ul {
		margin-bottom: 1.5rem;

		@include breakpoint(large) {
			margin-bottom: 0;
		 }
		
		li {
			a {
				color: $dark;

				&:hover, &.active {
					color: darken($medium, 20%);
				}
			}
		}
	}
	ul.sub {
		margin: 7px 0;
		li {
			margin-bottom: 0;
			display: flex;
			position: relative;
			padding-left: 2rem;
			flex-direction: column;

			&:before {
				content: "";
				background-color: darken($medium, 3%);
				width: 2px;
				position: absolute;
				top:0;
				bottom: 0;
				height: 100%;
				left: 1rem;
				max-height: 1.6rem;
			}

			a {
				color: $dark;

				&:hover, &.active {
					color: darken($medium, 20%);
				}
			}

			ul.sub {
				li {
					margin-bottom: 0;
					&:before {
						content: "+";
						color: $dark;
						background-color: transparent;
					}
				}
			}
		}
	}
}


body.news {
	hr {
		margin: 3rem 0 0;

	}

	.gallery {
		img {
			background-color: $medium;
			padding: 1.5rem;
			margin-top: 1.5rem;
		}
	}

	h2 {
		margin-top: 5rem;
	}

	.newsInfo {
		background-color: $secondary;
		color: $light;
		width: 100%;
		text-align: center;
		padding: 1.5rem;

		@include breakpoint(large) {
			padding: 2.5rem;
		 }

		@include breakpoint(huge) {
			padding: 3.5rem;
		 }
		
		

		p:last-child {
			margin-bottom: 0 !important;
		}
	}
}
.pdf-download {
		margin-top: 1rem;
		display: block;
		&:before {
			@extend .icon;
			@extend .icon-arrow;
			color: darken($medium, 15%);
			font-size: rem(13px);
			margin-right: 0.5rem;
		}
	}

.galleryHR {
	margin: 2rem 0 4rem;
}

.privacyControlBadge {
	background-color: $secondary;
	bottom: rem(235px);

	&:hover {
		background-color: $primary;
	}
}

body.index {
	.ewcms_news_feed {
		padding: 2.5rem 1.5rem;
		border-radius: 16px;
		background-color: $light;

		@include breakpoint(large) {
			padding: 2.5rem;
		 }

		@include breakpoint(giant) {
			padding: 3rem;
		 }
		
		@include breakpoint(full) {
			padding: 3.5rem;
		 }
		
		div {
			display: flex;
			flex-direction: column;
		}

		h3 {
			font-weight: 600;
		}

		a {
			@extend .btn;
			display: inline-flex;
			align-self: flex-end;
		}
	}
}

body:not(.index) {
	#header, .claimRow {
		display: none;
	}

	.greyBg {
		background-color: lighten($medium, 1%);
	}

	.hide {
		display: none;
	}

	.navCol {
		background-color: $light;
	}
}

body.download {
	h3 {
		margin-top: 3.5rem;
	}

	img {
		border: 1px solid $medium;
	}
}

body.produkteIndex {
	hr {
		margin: 1.5rem 0 3.5rem;
	}
}

// ACCORDION 
/////////////////////////

.alignCenter {
	display: flex;
	align-items: center;
}

.acc {
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		margin-top: 2rem;
	}
}

.description-title {
    font-size: rem(16px);
    background-color: $primary !important;
    color: $light;
    padding-left: 2rem;
    padding-top: 0.75rem;
    padding-right: 3rem !important;
    padding-bottom: 0.75rem !important;
    line-height: 23px;
    transition: 0.3s;
    margin-top: 2px;
    font-family: $displayFont;
    margin-bottom: 2px;
    position: relative;

    @include breakpoint(tiny) {
    	font-size: rem(17px);
    	line-height: 1.5rem;
     }

    @include breakpoint(medium) {
    	font-size: rem(18px);
    	position: relative;
     }


    @include breakpoint(giant) {
    	font-size: rem(20px);
    	position: relative;
     }    
    
    &:hover {
        background-color: $primary;
        cursor: pointer;
    }
     
}
.expand-collapse{
        float: right;
        margin-right: 1rem;
   		right: 0.5rem;
   		position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
    }
.description {
    font-size: 18px;
    color: $dark;
    max-height: 0;
    overflow: hidden;
    margin-left: 0px;
    transition: max-height 0.2s ease-out;
    text-align: left;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: lighten($medium, 2%) !important;
    p {
        margin-top: 1rem;
    }

    ul {
    	margin-top: 1rem;
    }

    .contentImg {
    	margin-top: 1.5rem;
    }
}

body.serviceIndex {
	.btn {
		display: flex;
	}
}

body.anfahrt {
	.googleMaps {
		height: 16rem;
		padding-bottom: 0;
		padding-top: 0;
		margin-top: 2.5rem;

		@include breakpoint(large) {
			height: 100%;
			margin-top: 0;
		 }
		
	}

	fieldset, button#submit {
		margin-bottom: 0 !important;
	}

	small {
		margin: 1rem 0;
	}
}

body.kontakt {
	hr, .hr {
		margin: 2.5rem 0;
	}

	.special {
		label {
			padding: 0.5rem 0;
			font-size: rem(17px);
			line-height: 110%;
			display: flex;
			align-items: center;
		}
	}
}

body.wartungsformular {
	.anrede {
		display: flex;
		justify-content: space-between;
	}
	label.radio {
		display: flex;
		align-items: center;
	}

	.group {
		display: flex;
		justify-content: space-between;

		label {
			padding-top: 0;
		}
	}

	span.headline {
		background-color: $primary;
		padding: 0.75rem;
		color: $light;
		display: block;
		margin-top: 3rem;
		margin-bottom: 1rem;
	}

	.flexCenter {
		@include breakpoint(medium) {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		 }
		
	}
}


.yourrateFixed {
    @include breakpoint(medium) {
        display: block;
        position: fixed;
        right: rem(-400px);
        top:20%;
        transition: linear .5s all;
        height:rem(120px);
		z-index: 99;
    }
    
    @include breakpoint(large) {
        top: 26%;
    }
    
    @include breakpoint(giant) {
        top: 32%;
    }
    
    &:hover{
        right: 0;
    }
    
    .siegelFixed {
        display: none;
    
        @include breakpoint(medium) {
            display: block;
            float:left;
            padding-right: 0rem;
        }
    }
    
    .widgetFixed {
        width: 100%;
        margin: 1rem 0;
		

        
        @include breakpoint(medium) {
            float:right;
            width:rem(400px);
            margin: 0;
        }
    
        .WidgetStandard{
            display: none;
    
            @include breakpoint(medium) {
                display: block;
            }
        }
    
        .widgetFlex{
            display: block;
			
    
            @include breakpoint(medium) {
                display: none;
            }
        }
    }

	.WidgetSeal{
		@include breakpoint (large, max){
			bottom: rem(300px);
			position: relative;
			z-index: 999 !important;
			 @include breakpoint( medium, max){
				display: none;
			 }
		}
	}
	.WidgetPopup{
		top: rem(70px);

		@include breakpoint (large){
			top: rem(165px);
		}

	}
	.widgetFlex{
		bottom: rem(55px);
	}
}

.WidgetSealContainer {
	z-index: 99 !important;
}
