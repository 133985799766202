* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
  max-width: 2100px;
  margin: 0 auto;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  line-height: 130%;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/

  span {
    font-weight: 300;
    display: block;
  }
}

h1,
.h1 {
  font-family: $displayFont;
  font-size: rem($h3Size);
  text-align: center;
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 2.5rem !important;

  @include breakpoint(medium) {
    font-size: rem($h2Size);
   }
  
  @include breakpoint(giant) {
    font-size: rem($h1Size);
   }

  @include breakpoint(huge) {
    padding-bottom: 2.5rem;
   }
  

   &:before {
    content: "";
    width: 60%;
    height: 1px;
    background-color: lighten($dark, 60%);
    display: block;
    left: 20%;
    position: absolute;
    bottom: 0;

    @include breakpoint(huge) {
      width: 50%;
      left: 25%;
     }
    
   }

   &:after {
    content: "\e913";
    font-family: "icomoon";
    color: $primary;
    font-size: rem(23px);
    background-color: $light;
    padding: 0.35rem 0.6rem;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(huge) {
      padding: 1.35rem 0.6rem;
     }
    
   }
  
}

h2,
.h2 {
  font-size: rem($h4Size);

  @include breakpoint(medium) {
    font-size: rem($h3Size);
   }

  @include breakpoint(giant) {
    font-size: rem($h2Size);
   }
      
}

h3,
.h3 {
  font-size: rem($h5Size);

  @include breakpoint(medium) {
    font-size: rem($h4Size);
   }
  
  @include breakpoint(giant) {
    font-size: rem($h3Size);
   }
  
}

h4,
.h4 {
  font-size: rem($h6Size);

  @include breakpoint(medium) {
    font-size: rem($h5Size);
   }
  
  @include breakpoint(giant) {
     font-size: rem($h4Size);
   }
  
}

h5,
.h5 {
  font-size: rem($h6Size);

  @include breakpoint(giant) {
    font-size: rem($h5Size);
   }
  
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $secondary;
  word-wrap: break-word;
  text-decoration: none;
  transition: 0.3s ease-in-out;

  &:hover {
    color: $primary;
  }
  &:focus {
    color: darken($primary, 15%);
  }
  &:active {
    color: darken($primary, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: darken($medium, 10%);
  border: none;
  clear: both;
  height: rem(1px);
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding: 0.6rem 1.25rem 0.6rem 1.25rem;
      margin-bottom: rem(15px);
      position: relative;
      border-bottom: 1px solid $medium;

      &:before {
        content: "";
        background-color: darken($medium, 3%);
        width: 5px;
        height: 100%;
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}

.contentSpace {
  padding: 3rem 0;

  @include breakpoint(medium) {
    padding: 4rem 0;
   }

  @include breakpoint(giant) {
    padding: 6rem 0;
   }

  @include breakpoint(full) {
    padding: 8rem 0;
   }  
}


// BODY CLASSES
////////////////////////

body.index {
  main {
    &.contentSpace {
      padding: 0;
    }
  }
}