// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1rem rem($baseGap);
	text-decoration: none;
	color: $navColor;
	font-size: rem(18px);
	transition: 0.3s ease-in-out;

	@include breakpoint($break) {
		font-weight: 500;
		color: $light;
		padding: 0.8rem rem($baseGap);
	 }
	

}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint($break) {
		background-color: transparent;
		color: lighten($primary, 25%);
	 }
	
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $primary;

	@include breakpoint($break) {
		background-color: transparent;
		color: lighten($primary, 25%);
	 }
	
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: "+";
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 70px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	.topbar {
		background-color: $light;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	}

	nav {

		@include ul {
			&.navi {
					margin-bottom: 4rem;
				li {
					&.suchergebnisse {
						display: none;
					}
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

@include breakpoint($break, max) {
	#navigation {
		.topbar {
			.row {
				.col {
					a.branding, div.openingTimes, div.hotline {
						display: none;
					}
				}
			}
		}

		.row {
			.col {
				&.noMarg {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
		nav {
			ul {
				&.navi {
					li {
						&.navBack {
							&:before {
								content: "\e903";
								font-size: rem(12px);
								color: $secondary;
							}

							&:hover {
								&:before {
									color: $light;
								}
							}
						}
					}
				}
			}
		}
	}
 }


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background-color: $primary;
		position: sticky;
		
		.topbar {
			.row {
				.col {
					display: flex;
					justify-content: space-between;
					align-items: center;

					a.branding {
						width: rem(150px);
						flex-shrink: 0;
						margin-right: 3.5rem;
					}

					.navRight {
						width: 100%;
						display: flex;
						justify-content: space-between;
					}

					.hotline {
						display: flex;
						align-items: center;
						flex-shrink: 0;

						span {
							line-height: 125%;
							font-size: rem(17px);

							strong {
								display: block;
								font-weight: 500;
							}
						}

						i {
							color: $light;
							background-color: $primary;
							border-radius: 10px;
							width: 2.1rem;
							height: 2.1rem;
							font-size: rem(16px);
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 0.5rem;
							flex-shrink: 0;
						}
					}

					.openingTimes {
						display: flex;
						align-items: center;
						flex-shrink: 0;
						width: 14rem;

						dl {
							dd, dt {
								font-size: rem(17px);
								line-height: 125%;
								display: inline-block;
							}

							dt {
								width: 35%;
								float: left;
								font-weight: 500;
							}

							dd {
								width: 65%;
								float: left;
							}
						}

						i {
							color: $light;
							background-color: $primary;
							border-radius: 10px;
							width: 2.1rem;
							height: 2.1rem;
							font-size: rem(13px);
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 0.5rem;
							flex-shrink: 0;
						}
					}
				}
			}
		}
		nav {
			ul {
				&.navi {
					margin-bottom: 0;
					li {
						&.datenschutzerklaerung, &.impressum, &.anfahrt {
							display: none;
						}

						&.startseite {
							a {
								padding-left: 0;
							}
						}

						&.kontakt {
							a {
								padding-right: 0;
							}
						}
						&.hasSub {
							.sub {
								background-color: $primary;
								li {
									a {
										color: $light;
										padding: 0.75rem 1rem;

										&:hover, &.active, &:focus {
											background-color: lighten($primary, 15%);
											color: $light;
										}
									}
								}
							}
						}
						&.navBack {
							padding: 0.5rem 1rem;
							background-color: lighten($primary, 7%);
						}
					}
				}
			}
		}
	}
}

@include breakpoint(giant) {
	#navigation {
		.topbar {
			.row {
				.col {
					a.branding {
						width: rem(185px);
						margin: 1rem 0;
					}

					.navRight {
						width: 75%;
						max-width: 49rem;
					}

					.hotline {
						span {
							font-size: rem(18px);
						}
					}

					.openingTimes {
						font-size: rem(18px);
					}
				}
			}
		}
	}
 }


// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: rem($baseGap);
	z-index: 1000;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);

	a.branding {
		width: rem(150px);
	}

	label {
		background-color: $primary;
		width: 4.5rem !important;
		height: 100% !important;
	}

	@include breakpoint($break) {
		display: none;
	}
}


// naviAdd
////
.naviAdd {
	display: flex;
	justify-content: center;

	li {
		margin-right: 1rem;
	}
}



// Navi Search Form Styles 
////

.search {
		div {
			form[name=searchForm] {
				display: none;

				&.preview  {
					display: block;
				}
			}
		}
	}

.flexForm {
	display: flex;
	margin: 1rem 0;

		button.send {
		background-color: $secondary;
		height: 2.1rem;
		width: 2.1rem;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		float: left;
		z-index: 0;
		cursor: pointer;
		transition: 0.3s ease-in-out;

		&:hover {
			background-color: darken($secondary, 5%);
		}

		i {
			color: $light;
			font-weight: 300;
			font-size: rem(15px);
		}
	}

	input {
		border: 1px solid darken($medium, 15%);
		border-radius: 10px;
		margin-left: -1.5rem;
		padding-left: 2rem;
		padding-right: 0.5rem;
	}
}
